import {useCallback} from "react";
import * as React from "react";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import Button from "../UI/Button";
import * as coursesStyles from "./courses.module.css";

const CourseCard = ({image, tags, title, description, url}) => {
    const tagsArray = tags.split(",");

    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#request-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <article className={coursesStyles.courses__course}>
            {image && (
                <div>
                    <Media
                        media={{content: image, youtubeVideoURL: ""}}
                        className={coursesStyles.courses__courseImage}
                    />
                </div>
            )}
            <div className={coursesStyles.courses__courseInfo}>
                {tagsArray.length > 0 && (
                    <div>
                        {tagsArray.map((tag, idx) => {
                            return (
                                <span key={idx} className={coursesStyles.courses__courseTag}>
                                    {tag.trim()}
                                </span>
                            );
                        })}
                    </div>
                )}
                <h3 className={coursesStyles.courses__courseTitle}>
                    <TemplateString template={title} />
                </h3>
                <p className={coursesStyles.courses__courseDesc}>
                    <TemplateString template={description} />
                </p>
                <div className={coursesStyles.courses__courseButtons}>
                    <Button variant="primary" onClick={scrollToForm}>
                        Оставить заявку
                    </Button>
                    {url && (
                        <Button isLink href={url}>
                            Узнать подробнее
                        </Button>
                    )}
                </div>
            </div>
        </article>
    );
};

export default CourseCard;
