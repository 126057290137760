// extracted by mini-css-extract-plugin
export var program = "program-module--program--DkXuK";
export var program__section = "program-module--program__section---7sfw";
export var program__container = "program-module--program__container--w83-m";
export var program__kicker = "program-module--program__kicker--rB0qS";
export var program__title = "program-module--program__title--FWQJy";
export var program__desc = "program-module--program__desc--LHnQx";
export var program__button = "program-module--program__button--wYLJS";
export var program__button_primary = "program-module--program__button_primary--UrkAR";
export var program__button_secondary = "program-module--program__button_secondary--qxkzP";
export var program__image = "program-module--program__image--ntYVo";
export var program__gradient = "program-module--program__gradient--vvdwN";