import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import ContentBlock from "../Layout/ContentBlock";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as aboutStyles from "./about.module.css";

const About = ({title, description, image}) => {
    const rightContent = (
        <div className={aboutStyles.about__imageWrapper}>
            <Media media={{content: image, youtubeVideoURL: ""}} className={aboutStyles.about__image} />
            <StaticImage
                src="../../assets/images/school/about/gradient_desktop.png"
                alt=""
                layout="fixed"
                placeholder="none"
                loading="eager"
                className={aboutStyles.about__gradient}
            />
            <StaticImage
                src="../../assets/images/school/about/gradient_tablet.png"
                alt=""
                layout="fixed"
                placeholder="none"
                loading="eager"
                className={`${aboutStyles.about__gradient} ${aboutStyles.about__gradient_tablet}`}
            />
            <StaticImage
                src="../../assets/images/school/about/gradient_mobile.png"
                alt=""
                layout="fixed"
                placeholder="none"
                loading="eager"
                className={`${aboutStyles.about__gradient} ${aboutStyles.about__gradient_mobile}`}
            />
        </div>
    );

    return (
        <ContentBlock
            alignLeft
            title={<TemplateString template={title} />}
            desc={<TemplateString template={description} />}
            rightContent={rightContent}
        />
    );
};

export default About;
