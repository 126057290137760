import {useCallback} from "react";
import * as React from "react";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import Button from "../UI/Button";
import * as programStyles from "./program.module.css";

const Program = ({kicker, title, description, url, image}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#request-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <Section className={programStyles.program__section} containerClassName={programStyles.program__container}>
            <div className={programStyles.program}>
                <div>
                    <p className={programStyles.program__kicker}>{kicker}</p>
                    <h2 className={programStyles.program__title}>
                        <TemplateString template={title} />
                    </h2>
                    <p className={programStyles.program__desc}>{description}</p>
                    <div>
                        <Button
                            className={`${programStyles.program__button} ${programStyles.program__button_primary}`}
                            onClick={scrollToForm}
                        >
                            Оставить заявку
                        </Button>
                        {url && (
                            <Button
                                className={`${programStyles.program__button} ${programStyles.program__button_secondary}`}
                                isLink
                                href={url}
                            >
                                Узнать подробнее
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <Media media={{content: image, youtubeVideoURL: ""}} className={programStyles.program__image} />
                </div>
            </div>
            <div>
                <div className={programStyles.program__gradient} />
                <div className={programStyles.program__gradient} />
                <div className={programStyles.program__gradient} />
            </div>
        </Section>
    );
};

export default Program;
