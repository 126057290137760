import {useMediaQuery} from "@react-hook/media-query";
import {useEffect, useState} from "react";
import * as React from "react";
import Section from "../Layout/Section";
import SectionTitle from "../Layout/Section/Title";
import CourseCard from "./CourseCard";
import Button from "../UI/Button";
import * as coursesStyles from "./courses.module.css";

const Courses = ({courses}) => {
    const isTwoColumnLayout = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");

    const [activeCoursesType, setActiveCoursesType] = useState("interactive");
    const [visibleCourses, setVisibleCourses] = useState([]);

    useEffect(() => {
        setVisibleCourses(courses.filter(({type}) => type === activeCoursesType));
    }, [activeCoursesType, courses]);

    return (
        <Section containerClassName={coursesStyles.courses}>
            <SectionTitle>
                Начните учиться <span>бесплатно</span>
            </SectionTitle>
            <div className={coursesStyles.courses__buttonContainer}>
                <Button
                    variant="gradient"
                    onClick={() => setActiveCoursesType("interactive")}
                    isActive={activeCoursesType === "interactive"}
                >
                    Интерактивные курсы
                </Button>
                <Button
                    variant="gradient"
                    onClick={() => setActiveCoursesType("online")}
                    isActive={activeCoursesType === "online"}
                >
                    Онлайн-обучение с экспертом
                </Button>
            </div>
            <div className={coursesStyles.courses__list}>
                {isTwoColumnLayout
                    ? visibleCourses
                          .reduce(
                              (acc, course, idx) => {
                                  acc[idx % 2].push(<CourseCard key={course.id} {...course} />);
                                  return acc;
                              },
                              [[], []],
                          )
                          .map((column, idx) => <div key={`column_${idx}`}>{column}</div>)
                    : visibleCourses.map((course) => <CourseCard key={course.id} {...course} />)}
            </div>
        </Section>
    );
};

export default Courses;
