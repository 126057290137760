import {graphql} from "gatsby";
import * as React from "react";
import {CourseJsonLd} from "gatsby-plugin-next-seo";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import Hero from "../components/School/Hero";
import About from "../components/School/About";
import Program from "../components/School/Program";
import Formats from "../components/School/Formats";
import Courses from "../components/School/Courses";
import CourseRequestForm from "../components/School/CourseRequestForm";

const SchoolPage = ({data: {pageData}, location}) => {
    const {hero, about, program, courses, seo} = pageData;

    return (
        <Layout>
            <Seo seo={seo} />
            <CourseJsonLd
                name="Школа ITSM 365"
                providerName="ITSM 365"
                providerUrl={location.origin}
                description="обучаем новичков и помогаем опытным специалистам применять новые знания в деле"
            />
            <Hero {...hero} />
            <About {...about} />
            <Program {...program} />
            <Formats />
            <Courses courses={courses} />
            <CourseRequestForm />
        </Layout>
    );
};

export default SchoolPage;

export const pageQuery = graphql`
    query {
        pageData: strapiSchool {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            hero {
                title
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                            }
                        }
                    }
                }
                variant
            }
            about {
                title
                description
                image {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            program {
                kicker
                title
                description
                url
                image {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            courses {
                id
                type
                tags
                title
                description
                url
                image {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;
