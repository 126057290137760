import * as React from "react";
import Monitor from "../../assets/svg/school/formats/monitor.svg";
import Player from "../../assets/svg/school/formats/player.svg";
import Section from "../Layout/Section";
import * as formatsStyles from "./formats.module.css";

const Formats = () => {
    return (
        <Section containerClassName={formatsStyles.formats} title="Форматы обучения">
            <div className={formatsStyles.formats__container}>
                <div className={formatsStyles.formats__item}>
                    <Monitor />
                    <h3 className={formatsStyles.formats__itemTitle}>Интерактивные курсы</h3>
                    <p className={formatsStyles.formats__itemDesc}>
                        Обучение работе с системой в игровом формате: от новичка до профи
                    </p>
                </div>
                <div className={formatsStyles.formats__item}>
                    <Player />
                    <h3 className={formatsStyles.formats__itemTitle}>Онлайн-обучение с экспертом</h3>
                    <p className={formatsStyles.formats__itemDesc}>
                        Лекции и практические задания для тех, кто планирует самостоятельно дорабатывать ITSM 365 под
                        бизнес-процессы компании
                    </p>
                </div>
            </div>
        </Section>
    );
};

export default Formats;
