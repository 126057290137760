import classNames from "classnames";
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import Container from "../Layout/Container";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as heroStyles from "./hero.module.css";

const Hero = ({title, media, variant = "text_above"}) => {
    return (
        <Container
            className={classNames({[heroStyles.hero]: true, [heroStyles.hero_textLeft]: variant === "text_left"})}
        >
            <h1 className={heroStyles.hero__title}>
                <TemplateString template={title} />
            </h1>
            {media && (
                <div className={heroStyles.hero__media}>
                    <Media media={media} big={variant !== "text_left"} imageProps={{loading: "eager"}} />
                </div>
            )}
            <div>
                <StaticImage
                    src="../../assets/images/school/hero/gradient_desktop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={heroStyles.hero__gradient}
                />
                <StaticImage
                    src="../../assets/images/school/hero/gradient_tablet.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/school/hero/gradient_mobile.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_mobile}`}
                />
            </div>
        </Container>
    );
};

Hero.propTypes = {
    variant: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    media: PropTypes.exact({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.shape({
            mime: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            localFile: PropTypes.shape({
                publicURL: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.shape({}),
                }),
            }),
        }),
    }).isRequired,
};

export default Hero;
