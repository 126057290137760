// extracted by mini-css-extract-plugin
export var courses = "courses-module--courses--dm9pK";
export var courses__buttonContainer = "courses-module--courses__button-container--VpXBW";
export var courses__course = "courses-module--courses__course--q+faT";
export var courses__courseInfo = "courses-module--courses__course-info--0PuoD";
export var courses__courseImage = "courses-module--courses__course-image--+D6wq";
export var courses__courseTag = "courses-module--courses__course-tag--61DJl";
export var courses__courseTitle = "courses-module--courses__course-title--cARPP";
export var courses__courseDesc = "courses-module--courses__course-desc--VeyfA";
export var courses__courseButtons = "courses-module--courses__course-buttons--mBi3U";
export var courses__list = "courses-module--courses__list--9R7n8";